import { MemberReferInfosModel } from '../../../common/src';
import * as backendApi from '../shared/backend-api';

export default class ReferAFriendService {
  constructor() {}

  getMemberReferInfos(): Promise<MemberReferInfosModel> {
    return backendApi.get('refer-a-friend/member-refer-infos');
  }

  validateReferCode(refererId: number, refererCenterId: number, friendCode: string): Promise<MemberReferInfosModel> {
    return backendApi.post('refer-a-friend/validate-refer-code', {
      refererId,
      refererCenterId,
      friendCode,
    });
  }
}
