import store from '@/store';
import {
  AccountOverviewConsolidatedInvoiceModel,
  AccountOverviewPayableDebtStatus,
  AccountReceivableType,
  CompositeKeyModel,
  CompositeSubKeyModel,
  DirectDebitPaymentModel,
  PayDebtCheckModel,
  PaymentAgreenentModel,
  PaymentLinkModel,
  PaymentOptionsModel,
  PaymentProvidersEnum,
  PaymentResultModel,
  PayWayResponse,
  ShoppingBasketModel,
} from '../../../common/src';
import * as backendApi from '../shared/backend-api';

const baseUrl = window.location.origin;
export default class PaymentService {
  constructor() {}

  makeCCClipcardPayment(
    selectedUserId: number,
    selectedUserCenterId: number,
    clipcardId: number,
    clipcardCenterId: number,
    clipcardSize: number | undefined,
    priceOverride: number | null,
    provider: PaymentProvidersEnum | undefined,
    externalId: string | undefined
  ): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-cc-clipcard-payment', {
      selectedUserId,
      selectedUserCenterId,
      clipcardId,
      clipcardCenterId,
      clipcardSize,
      provider,
      baseUrl,
      priceOverride,
      externalId,
    });
  }

  makeCCShoppingBasketPayment(
    selectedUserId: number,
    selectedUserCenterId: number,
    shoppingBasketId: string,
    provider: PaymentProvidersEnum
  ): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-cc-shopping-basket-payment', {
      selectedUserId,
      selectedUserCenterId,
      shoppingBasketId,
      provider,
      baseUrl,
    });
  }

  makeCCClassPayment(
    selectedUserId: number,
    selectedUserCenterId: number,
    clipcardId: number,
    clipcardCenterId: number,
    clipcardSize: number | undefined,
    bookingId: number,
    bookingCenterId: number,
    provider: PaymentProvidersEnum | undefined,
    externalId: string | undefined
  ): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-cc-class-booking-payment', {
      selectedUserId,
      selectedUserCenterId,
      clipcardId,
      clipcardCenterId,
      clipcardSize,
      bookingId,
      bookingCenterId,
      provider,
      baseUrl,
      externalId,
    });
  }

  makeCCResourceBookingPayment(
    selectedUserId: number,
    selectedUserCenterId: number,
    clipcardId: number,
    clipcardCenterId: number,
    clipcardSize: number | undefined,
    activityCenterId: number,
    activityId: number,
    resourceKey: number,
    startTime: string,
    date: string,
    duration: number,
    provider: PaymentProvidersEnum | undefined,
    externalId: string | undefined
  ): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-cc-resource-booking-payment', {
      selectedUserId,
      selectedUserCenterId,
      clipcardId,
      clipcardCenterId,
      clipcardSize,
      activityCenterId,
      activityId,
      resourceKey,
      startTime,
      date,
      duration,
      provider,
      baseUrl,
      externalId,
    });
  }

  makeCoFClipcardPayment(
    selectedUserId: number,
    selectedUserCenterId: number,
    clipcardId: number,
    clipcardCenterId: number,
    clipcardSize: number | undefined,
    priceOverride: number | null,
    selectedCardOnFile: PaymentAgreenentModel
  ): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-cof-clipcard-payment', {
      selectedUserId,
      selectedUserCenterId,
      clipcardId,
      clipcardCenterId,
      clipcardSize,
      selectedCardOnFile,
      priceOverride,
    });
  }

  makeCoFClassPayment(
    selectedUserId: number,
    selectedUserCenterId: number,
    clipcardId: number,
    clipcardCenterId: number,
    clipcardSize: number | undefined,
    bookingId: number,
    bookingCenterId: number,
    selectedCardOnFile: PaymentAgreenentModel
  ): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-cof-class-payment', {
      selectedUserId,
      selectedUserCenterId,
      clipcardId,
      clipcardCenterId,
      clipcardSize,
      bookingId,
      bookingCenterId,
      selectedCardOnFile,
    });
  }

  makeCoFResourceBookingPayment(
    selectedUserId: number,
    selectedUserCenterId: number,
    clipcardId: number,
    clipcardCenterId: number,
    clipcardSize: number | undefined,
    activityCenterId: number,
    activityId: number,
    resourceKey: number,
    startTime: string,
    date: string,
    duration: number,
    selectedCardOnFile: PaymentAgreenentModel
  ): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-cof-resource-booking-payment', {
      selectedUserId,
      selectedUserCenterId,
      clipcardId,
      clipcardCenterId,
      clipcardSize,
      activityCenterId,
      activityId,
      resourceKey,
      startTime,
      date,
      duration,
      selectedCardOnFile,
    });
  }

  makeCCCoursePayment(
    selectedUserId: number,
    selectedUserCenterId: number,
    provider: PaymentProvidersEnum | undefined
  ): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-cc-course-payment', {
      selectedUserId,
      selectedUserCenterId,
      provider,
      baseUrl,
    });
  }

  makeCoFCoursePayment(
    selectedUserId: number,
    selectedUserCenterId: number,
    selectedCardOnFile: PaymentAgreenentModel
  ): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-cof-course-payment', { selectedUserId, selectedUserCenterId, selectedCardOnFile });
  }

  makeCCCampsPayment(
    selectedUserId: number,
    selectedUserCenterId: number,
    provider: PaymentProvidersEnum | undefined,
    selectedPaymentOption: PaymentOptionsModel | null
  ): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-cc-camps-payment', {
      selectedUserId,
      selectedUserCenterId,
      provider,
      baseUrl,
      selectedPaymentOption,
    });
  }

  makeCoFCampsPayment(
    selectedUserId: number,
    selectedUserCenterId: number,
    selectedCardOnFile: PaymentAgreenentModel,
    selectedPaymentOption: PaymentOptionsModel | null
  ): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-cof-camps-payment', {
      selectedUserId,
      selectedUserCenterId,
      selectedCardOnFile,
      selectedPaymentOption,
    });
  }

  makePLUpdateAgreementPayment(
    provider: PaymentProvidersEnum,
    paymentAgreementKeyToUpdate: CompositeSubKeyModel,
    isDDPaymentLink: boolean
  ): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-pl-update-agreement-payment', {
      baseUrl,
      provider,
      paymentAgreementKeyToUpdate,
      isDDPaymentLink,
    });
  }

  makeACHUpdatePaymentAgreement(
    selectedDirectDebit: DirectDebitPaymentModel,
    paymentAgreementKeyToUpdate: CompositeSubKeyModel
  ): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-dd-update-agreement-payment', {
      selectedDirectDebit,
      paymentAgreementKeyToUpdate,
    });
  }

  makePLNewSubscriptionPayment(
    subscriptionCenterId: number,
    subscriptionId: number,
    startupDate: string,
    addOns: number[],
    promoCode: string,
    provider: PaymentProvidersEnum,
    type: 'NEW' | 'ADD',
    selectedUserId?: number,
    selectedUserCenterId?: number,
    clipCardId?: string,
    clipCardSize?: string,
    isDDPaymentLink?: boolean,
    referAFriend?: string
  ): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-pl-new-subscription-payment', {
      baseUrl,
      subscriptionCenterId,
      subscriptionId,
      startupDate,
      addOns,
      promoCode,
      provider,
      type,
      selectedUserId,
      selectedUserCenterId,
      clipCardId,
      clipCardSize,
      isDDPaymentLink,
      referAFriend,
    });
  }

  makeCCCASHUpfrontPayment(
    subscriptionCenterId: number,
    subscriptionId: number,
    startupDate: string,
    addOns: number[],
    promoCode: string,
    selectedFeesCreditCard: PaymentProvidersEnum,
    type: 'NEW' | 'ADD',
    selectedUserId?: number,
    selectedUserCenterId?: number,
    selectedPaymentOptionExternalId?: string,
    clipCardId?: string,
    clipCardSize?: string,
    referAFriend?: string
  ): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-cc-cash-upfront-subscription-payment', {
      baseUrl,
      subscriptionCenterId,
      subscriptionId,
      startupDate,
      addOns,
      promoCode,
      selectedFeesCreditCard,
      type,
      selectedUserId,
      selectedUserCenterId,
      selectedPaymentOptionExternalId,
      clipCardId,
      clipCardSize,
      referAFriend,
    });
  }

  makeCoFCASHUpfrontPayment(
    subscriptionCenterId: number,
    subscriptionId: number,
    startupDate: string,
    addOns: number[],
    promoCode: string,
    selectedFeesCoF: PaymentAgreenentModel,
    type: 'NEW' | 'ADD',
    selectedUserId?: number,
    selectedUserCenterId?: number,
    selectedPaymentOptionExternalId?: string,
    clipCardId?: string,
    clipCardSize?: string
  ): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-cof-cash-upfront-subscription-payment', {
      subscriptionCenterId,
      subscriptionId,
      startupDate,
      addOns,
      promoCode,
      selectedFeesCoF,
      type,
      selectedUserId,
      selectedUserCenterId,
      selectedPaymentOptionExternalId,
      clipCardId,
      clipCardSize,
    });
  }

  makeCCEFTUpfrontPayment(
    subscriptionCenterId: number,
    subscriptionId: number,
    startupDate: string,
    addOns: number[],
    promoCode: string,
    selectedFeesCreditCard: PaymentProvidersEnum,
    type: 'NEW' | 'ADD',
    selectedUserId?: number,
    selectedUserCenterId?: number,
    clipCardId?: string,
    clipCardSize?: string
  ): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-cc-eft-upfront-subscription-payment', {
      baseUrl,
      subscriptionCenterId,
      subscriptionId,
      startupDate,
      addOns,
      promoCode,
      selectedFeesCreditCard,
      type,
      selectedUserId,
      selectedUserCenterId,
      clipCardId,
      clipCardSize,
    });
  }

  makeCoFEFTUpfrontPayment(
    subscriptionCenterId: number,
    subscriptionId: number,
    startupDate: string,
    addOns: number[],
    promoCode: string,
    selectedFeesCoF: PaymentAgreenentModel,
    type: 'NEW' | 'ADD',
    selectedUserId?: number,
    selectedUserCenterId?: number,
    clipCardId?: string,
    clipCardSize?: string
  ): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-cof-eft-upfront-subscription-payment', {
      subscriptionCenterId,
      subscriptionId,
      startupDate,
      addOns,
      promoCode,
      selectedFeesCoF,
      type,
      selectedUserId,
      selectedUserCenterId,
      clipCardId,
      clipCardSize,
    });
  }

  makeACHNewSubscriptionPayment(
    subscriptionCenterId: number,
    subscriptionId: number,
    startupDate: string,
    addOns: number[],
    promoCode: string,
    selectedDirectDebit: DirectDebitPaymentModel,
    selectedFeesCreditCard: PaymentProvidersEnum | PaymentAgreenentModel,
    type: 'NEW' | 'ADD',
    selectedUserId?: number,
    selectedUserCenterId?: number,
    clipCardId?: string,
    clipCardSize?: string,
    referAFriend?: string
  ): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-ach-new-subscription-payment', {
      baseUrl,
      subscriptionCenterId,
      subscriptionId,
      startupDate,
      addOns,
      promoCode,
      selectedDirectDebit,
      selectedFeesCreditCard,
      type,
      selectedUserId,
      selectedUserCenterId,
      clipCardId,
      clipCardSize,
      referAFriend,
    });
  }

  makeInvoiceUpfrontPayment(
    subscriptionCenterId: number,
    subscriptionId: number,
    startupDate: string,
    addOns: number[],
    promoCode: string,
    selectedFeesCreditCard: PaymentProvidersEnum,
    type: 'ADD',
    selectedUserId?: number,
    selectedUserCenterId?: number,
    clipCardId?: string,
    clipCardSize?: string,
    referAFriend?: string
  ): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-invoice-new-subscription-payment', {
      baseUrl,
      subscriptionCenterId,
      subscriptionId,
      startupDate,
      addOns,
      promoCode,
      type,
      selectedFeesCreditCard,
      selectedUserId,
      selectedUserCenterId,
      clipCardId,
      clipCardSize,
      referAFriend,
    });
  }

  makeCCDebtPayment(
    consolidatedInvoicesArray: AccountOverviewConsolidatedInvoiceModel[],
    accountOverviewPayableDebtStatus: AccountOverviewPayableDebtStatus,
    provider: PaymentProvidersEnum,
    payableDebtAmount: number,
    debtType: AccountReceivableType
  ): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-cc-debt-payment', {
      consolidatedInvoicesArray,
      baseUrl,
      accountOverviewPayableDebtStatus,
      provider,
      payableDebtAmount,
      debtType,
    });
  }

  preflightSADebtPayment(userExternalID: string, amount: number, consolidatedInv: string | undefined): Promise<PayDebtCheckModel> {
    return backendApi.post('payment/preflight-sa-debt-payment', {
      userExternalID,
      amount,
      consolidatedInv,
    });
  }

  makeSACCDebtPayment(
    selectedUserId: number,
    selectedUserCenterId: number,
    staffEmpNumber: string,
    sessionKey: string,
    amount: number,
    consolidatedInv: string | undefined,
    paymentProvider: PaymentProvidersEnum
  ): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-cc-sa-debt-payment', {
      selectedUserId,
      selectedUserCenterId,
      baseUrl,
      staffEmpNumber,
      sessionKey,
      amount,
      consolidatedInv,
      paymentProvider,
    });
  }

  makeCoFChangeSubscriptionPayment(
    subscriptionCenterId: number,
    subscriptionId: number,
    startupDate: string,
    addOns: number[],
    promoCode: string,
    currentPlanId: number,
    currentPlanCenterId: number,
    selectedCardOnFile: PaymentAgreenentModel
  ): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-cof-change-subscription-payment', {
      subscriptionCenterId,
      subscriptionId,
      startupDate,
      addOns,
      promoCode,
      currentPlanId,
      currentPlanCenterId,
      selectedCardOnFile,
    });
  }

  makeChangeSubscriptionPayment(
    subscriptionCenterId: number,
    subscriptionId: number,
    startupDate: string,
    addOns: number[],
    promoCode: string,
    currentPlanId: number,
    currentPlanCenterId: number,
    feesProvider: PaymentProvidersEnum,
    newProviderForPaymentAgreement: PaymentProvidersEnum | 'INVOICE' | DirectDebitPaymentModel | undefined
  ): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-change-subscription-payment', {
      baseUrl,
      subscriptionCenterId,
      subscriptionId,
      startupDate,
      addOns,
      promoCode,
      currentPlanId,
      currentPlanCenterId,
      feesProvider: feesProvider,
      newProviderForPaymentAgreement: newProviderForPaymentAgreement,
    });
  }

  startClipcardSubscriptionPaymentSession(
    selectedUserId: number,
    selectedUserCenterId: number,
    subscriptionCenterId: number,
    shoppingBasketId: string
  ): Promise<ShoppingBasketModel> {
    return backendApi.post('payment/start-clipcard-subscription-payment-session', {
      selectedUserId,
      selectedUserCenterId,
      subscriptionCenterId,
      shoppingBasketId,
    });
  }

  createStaffClipcardShoppingBasket(
    selectedUserId: number,
    selectedUserCenterId: number,
    clipcardId: number,
    clipcardCenterId: number,
    clipSize: number | undefined,
    priceOverride: number | null
  ): Promise<ShoppingBasketModel> {
    return backendApi.post('payment/create-clipcard-staff-shopping-basket', {
      selectedUserId,
      selectedUserCenterId,
      clipcardId,
      clipcardCenterId,
      clipSize,
      priceOverride,
    });
  }

  makeACHCreatePaymentAgreement(selectedDirectDebit: DirectDebitPaymentModel): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-dd-create-agreement-payment', {
      selectedDirectDebit,
    });
  }

  makePLCreatePaymentAgreement(selectedCreditCard: PaymentProvidersEnum, isDDPaymentLink: boolean): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-pl-create-agreement-payment', {
      baseUrl,
      selectedCreditCard,
      isDDPaymentLink,
    });
  }

  makeInvoiceCreatePaymentAgreement(): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-invoice-create-agreement-payment', {});
  }

  makeInvoiceUpdatePaymentAgreement(paymentAgreementKeyToUpdate: CompositeSubKeyModel): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-invoice-update-agreement-payment', { paymentAgreementKeyToUpdate });
  }

  makeCCTopUpPayment(paymentProvider: PaymentProvidersEnum, topUpAmount: number): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-cc-top-up-account', {
      baseUrl,
      paymentProvider,
      topUpAmount,
    });
  }

  makeCCFreezeSubscriptionPayment(
    paymentProvider: PaymentProvidersEnum,
    fromDate: string,
    toDate: string,
    subscriptionId: number,
    subscriptionCenterId: number
  ): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-cc-freeze-subscription', {
      baseUrl,
      paymentProvider,
      fromDate,
      toDate,
      subscriptionId,
      subscriptionCenterId,
    });
  }

  checkPayment(
    selectedUserId: number,
    selectedUserCenterId: number,
    paymentId: string | null,
    transId: string | null,
    provider: PaymentProvidersEnum,
    isSuccess: boolean,
    paymentProviderErrorDesc: string | null
  ): Promise<PaymentResultModel> {
    return backendApi.post('payment/check-payment', {
      selectedUserId,
      selectedUserCenterId,
      paymentId,
      transId,
      provider,
      isSuccess,
      paymentProviderErrorDesc,
    });
  }

  // this is only called by the Adyen dropin component, there's no way we can test it
  /* istanbul ignore next */
  checkAdyenPayment(paymentKey: string): Promise<'PENDING' | 'SUCCESS' | 'FAILURE'> {
    return backendApi.post('adyen/adyen-check-payment', { paymentKey });
  }

  // this is only called by the PayWay Frame, there's no way we can test it
  /* istanbul ignore next */
  makePaywayPayment(singleUseTokenId: string, key: string): Promise<PayWayResponse> {
    return backendApi.post('payway/payway-make-payment', { key, singleUseTokenId });
  }

  makeBuyAddonPayment(
    addOns: number[],
    subscriptionId: string,
    subscriptionCenterId: number,
    feesProvider: PaymentProvidersEnum
  ): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-addon-payment', {
      baseUrl,
      feesProvider: feesProvider,
      addOns,
      subscriptionId,
      subscriptionCenterId,
    });
  }

  makeCoFBuyAddonPayment(
    addOns: number[],
    subscriptionId: string,
    subscriptionCenterId: number,
    selectedCardOnFile: PaymentAgreenentModel
  ): Promise<PaymentLinkModel> {
    return backendApi.post('payment/make-cof-addon-payment', {
      addOns,
      subscriptionId,
      subscriptionCenterId,
      selectedCardOnFile,
    });
  }
}
