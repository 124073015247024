import { DocumentModel, FilteredDocumentModel, MIMEDocumentModel, SignatureDataModel, UnsignedPdfDocumentModel } from '../../../common/src';
import * as backendApi from '../shared/backend-api';

export default class DocumentService {
  constructor() {}

  getJournalNotes(type: string | undefined): Promise<FilteredDocumentModel[]> {
    return backendApi.get('documents/getJournalNotes', { type });
  }
  downloadProfileDocument(documentId: number): Promise<DocumentModel> {
    return backendApi.post('documents/download-document', { documentId });
  }
  getPDFDocumentToSign(journalNoteId: number): Promise<UnsignedPdfDocumentModel> {
    // FIXME: type
    return backendApi.get(`documents/get-unsigned-document/${journalNoteId}`, {});
  }
  signPDFDocument(journalNoteId: number, signedDocument: MIMEDocumentModel, signatureDatas: SignatureDataModel[]): Promise<string> {
    return backendApi.post(`documents/sign-pdf-document`, { journalNoteId, signedDocument, signatureDatas });
  }
}
