import {
  SubscriptionModel,
  CampaignInformationForPersonModel,
  SelectedSubscriptionModel,
  SubscriptionContractSummaryModel,
  SuggestedTerminationDateModel,
  SubscriptionTypeModel,
  AvailableFreezeModel,
  WebLeadSummaryModel,
  FirstFreezePeriodModel,
  AddOnModel,
} from '../../../common/src';
import * as backendApi from '../shared/backend-api';

export default class SubscriptionService {
  constructor() {}

  getPersonAndRelativesSubscriptions(): Promise<SubscriptionModel[]> {
    return backendApi.post('subscription/load-person-and-relatives-subscriptions-for-profile');
  }

  getPersonSubscriptions(selectedUserId?: number, selectedUserCenterId?: number): Promise<SubscriptionModel[]> {
    return backendApi.post('subscription/load-person-subscriptions-for-profile', { selectedUserId, selectedUserCenterId });
  }

  getAvailableSubscriptionsForAnnonymous(centerId: number, campaignCode?: string): Promise<SubscriptionModel[]> {
    return backendApi.post('subscription/load-available-subscriptions-for-annonymous', { centerId, campaignCode });
  }

  getClipcardSubscriptionsForPerson(selectedUserId: number, selectedUserCenterId: number, centerId: number): Promise<SubscriptionModel[]> {
    return backendApi.post('subscription/load-available-clipcard-subscriptions-for-person', {
      selectedUserId,
      selectedUserCenterId,
      centerId,
    });
  }

  getSubscriptionsForPerson(
    centerId: number,
    bookingId: number,
    selectedUserId?: number,
    selectedUserCenterId?: number,
    campaignCode?: string
  ): Promise<SubscriptionModel[]> {
    return backendApi.post('subscription/load-available-subscriptions-for-person', {
      centerId,
      bookingId,
      selectedUserId,
      selectedUserCenterId,
      campaignCode,
    });
  }

  getAvailableSubscriptionsForChange(
    currentSubscriptionId: number,
    currentSubscriptionCenterId: number,
    bookingId: number,
    campaignCode?: string
  ): Promise<SubscriptionModel[]> {
    return backendApi.post('subscription/load-available-subscriptions-for-change', {
      currentSubscriptionId,
      currentSubscriptionCenterId,
      bookingId,
      campaignCode,
    });
  }

  validatePromocode(centerId: number, promoCode: string): Promise<CampaignInformationForPersonModel> {
    return backendApi.post('subscription/validate-promo-code', { centerId, promoCode });
  }

  getPlanSummaryForNew(
    planCenterId: number,
    planId: string | undefined,
    addOns: number[],
    promoCode: string
  ): Promise<SelectedSubscriptionModel> {
    return backendApi.post('subscription/get-plan-summary-for-new', { planCenterId, planId, addOns, promoCode });
  }

  getPlanSummaryForAdd(
    planCenterId: number,
    planId: string | undefined,
    addOns: number[],
    promoCode: string | undefined,
    selectedUserId?: number,
    selectedUserCenterId?: number
  ): Promise<SelectedSubscriptionModel> {
    return backendApi.post('subscription/get-plan-summary-for-add', {
      planCenterId,
      planId,
      addOns,
      promoCode,
      selectedUserId,
      selectedUserCenterId,
    });
  }

  getPlanSummaryForChange(
    planCenterId: number,
    planId: string | undefined,
    addOns: number[],
    promoCode: string,
    currentPlanId: number,
    currentPlanCenterId: number
  ): Promise<SelectedSubscriptionModel> {
    return backendApi.post('subscription/get-plan-summary-for-change', {
      planCenterId,
      planId,
      addOns,
      promoCode,
      currentPlanId,
      currentPlanCenterId,
    });
  }

  getContractSummaryForNew(
    subscriptionCenterId: number,
    subscriptionId: number,
    startDate: string,
    addOns: number[],
    promoCode: string,
    selectedClearingHouseType: 'EFT' | 'INVOICE' | 'CREDIT_CARD' | 'EFT_PAYMENT_LINK' | undefined,
    selectedUserId?: number,
    selectedUserCenterId?: number,
    referAFriend?: string
  ): Promise<SubscriptionContractSummaryModel> {
    return backendApi.post('subscription/get-contract-summary-for-new', {
      subscriptionCenterId,
      subscriptionId,
      addOns,
      startDate,
      promoCode,
      selectedUserId,
      selectedUserCenterId,
      selectedClearingHouseType,
      referAFriend,
    });
  }

  getContractSummaryForChange(
    subscriptionCenterId: number,
    subscriptionId: number,
    startDate: string,
    addOns: number[],
    promoCode: string,
    selectedClearingHouseType: 'EFT' | 'INVOICE' | 'CREDIT_CARD' | 'EFT_PAYMENT_LINK' | undefined,
    currentPlanId: number,
    currentPlanCenterId: number
  ): Promise<SubscriptionContractSummaryModel> {
    return backendApi.post('subscription/get-contract-summary-for-change', {
      subscriptionCenterId,
      subscriptionId,
      addOns,
      startDate,
      promoCode,
      currentPlanId,
      currentPlanCenterId,
      selectedClearingHouseType,
    });
  }

  // Terminate subscription

  getSuggestedTerminationDates(subscriptionId: number, subscriptionCenterId: number): Promise<SuggestedTerminationDateModel[]> {
    return backendApi.post('subscription/get-suggested-termination-dates', {
      subscriptionId,
      subscriptionCenterId,
    });
  }

  terminateSubscription(subscriptionId: number, subscriptionCenterId: number, subscriptionEndDate: string): Promise<void> {
    return backendApi.post('subscription/terminate-subscription', {
      subscriptionId,
      subscriptionCenterId,
      subscriptionEndDate,
    });
  }
  regretCancellation(subscriptionId: number, subscriptionCenterId: number): Promise<void> {
    return backendApi.post('subscription/regret-cancellation', {
      subscriptionId,
      subscriptionCenterId,
    });
  }

  // Freeze subscription
  freezeSubscription(subscriptionId: number, subscriptionCenterId: number, fromDate: string, toDate: string): Promise<void> {
    return backendApi.post('subscription/freeze-subscription', {
      subscriptionId,
      subscriptionCenterId,
      fromDate,
      toDate,
    });
  }

  // TODO Freeze - This call should not be blocking the user from moving to the next page
  // I will just not show period info if its retuns an error
  getFreezeDetailsForSubscription(subscriptionProductGlobalId?: string, subscriptionCenterId?: number): Promise<AvailableFreezeModel> {
    return backendApi.post('subscription/get-freeze-details-for-subscription', {
      subscriptionProductGlobalId,
      subscriptionCenterId,
    });
  }

  getAvailableFreeze(
    fromDate: string,
    toDate: string,
    subscriptionId: number,
    subscriptionCenterId: number
  ): Promise<AvailableFreezeModel> {
    return backendApi.post('subscription/get-available-freeze', {
      fromDate,
      toDate,
      subscriptionId,
      subscriptionCenterId,
    });
  }

  getFreezePeriodData(subscriptionId: number, subscriptionCenterId: number): Promise<FirstFreezePeriodModel> {
    return backendApi.post('subscription/get-freeze-period', {
      subscriptionId,
      subscriptionCenterId,
    });
  }

  cancelFreeze(freezeId: number, freezeCenterId: number, freezeSubId: number): Promise<void> {
    return backendApi.post('subscription/cancel-freeze', {
      freezeId,
      freezeCenterId,
      freezeSubId,
    });
  }

  stopFreeze(freezeId: number, freezeCenterId: number, freezeSubId: number): Promise<void> {
    return backendApi.post('subscription/stop-freeze', {
      freezeId,
      freezeCenterId,
      freezeSubId,
    });
  }

  acceptOffer(): Promise<void> {
    return backendApi.post('subscription/accept-offer', {});
  }

  loadConfigForLeadFlow(centerId: number): Promise<WebLeadSummaryModel> {
    return backendApi.post(`subscription/load-config-for-weblead`, { centerId });
  }

  loadConfigForStandaloneLeadFlow(centerId: number): Promise<WebLeadSummaryModel> {
    return backendApi.post(`subscription/load-config-for-standalone-weblead`, { centerId });
  }

  generateQualitopForm(
    healthInsurance: string,
    supplementaryHealthInsurance: string,
    insuranceNumber: string,
    selectedYear: string
  ): Promise<string> {
    return backendApi.post(`subscription/generate-qualitop-form`, {
      healthInsurance,
      supplementaryHealthInsurance,
      insuranceNumber,
      selectedYear,
    });
  }

  getAvailableAddonsForProducts(productGlobalId: string, center: number): Promise<SubscriptionModel> {
    return backendApi.post('subscription/load-addons-for-product', { productGlobalId, center });
  }

  getContractSummaryForBuyAddons(
    addOns: number[],
    subscriptionId: string,
    subscriptionCenterId: number
  ): Promise<SubscriptionContractSummaryModel> {
    return backendApi.post('subscription/get-contract-summary-for-buy-addons', {
      addOns,
      subscriptionId,
      subscriptionCenterId,
    });
  }

  terminateAddon(subscriptionAddonId: number, stopDate: string, subscriptionId: number, subscriptionCenterId: number): Promise<void> {
    return backendApi.post('subscription/terminate-addon', {
      subscriptionAddonId,
      stopDate,
      subscriptionId,
      subscriptionCenterId,
    });
  }
}
