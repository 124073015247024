import { Store } from 'vuex';
import { CenterModel, ConfigLookAndFeelModel, ConfigModel, ErrorModel, TranslationModel } from '../../../common/src';
import { setColor } from '@/shared/color-utils';
import { WebAppStoreState } from '@/store';
import { App } from 'vue';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import * as backendApi from '../shared/backend-api';
import i18n, { Language, loadEnglishLocale } from '@/i18n';

declare global {
  interface Window {
    dataLayer: any;
  }
}

// istanbul ignore next
const CONFIG_LOADING_DELAY = process.env.NODE_ENV === 'development' ? 3000 : 1000;

export default class ConfigurationService {
  constructor(public store: Store<WebAppStoreState>, public app: App<Element>) {}

  loadConfiguration(force: boolean, center?: number): Promise<ConfigModel> {
    // get the center from the url if it is there
    const url = new URL(window.location.href);
    const centerParam =
      (url.pathname.includes('/join') || url.pathname.includes('/create') || url.pathname.includes('/generate-lead')) &&
      url.searchParams.get('center')
        ? Number(url.searchParams.get('center'))
        : center;
    let queryParam = '';
    if (centerParam && this.store.state.config?.configCenterId !== centerParam) {
      queryParam = `?centerId=${centerParam}`;
    }

    if (!force && !queryParam && this.store.state.config !== undefined) {
      return new Promise<ConfigModel>((resolve, reject) => {
        resolve(this.store.state.config!);
      });
    } else {
      // this.store.commit('configError', undefined);
      return new Promise<ConfigModel>((resolve, reject) => {
        const startTime = Date.now();

        backendApi
          .get<ConfigModel>(`config/webapp-config${queryParam}`)
          .then((response: ConfigModel) => {
            const took = Date.now() - startTime;
            const sleep = Math.max(CONFIG_LOADING_DELAY - took, 0);
            if (!checkLookAndFeel(response.lookAndFeel)) {
              throw new ErrorModel('general.incompleteConfig');
            }
            if (!setCssVariables(response.lookAndFeel)) {
              throw new ErrorModel('general.incorrectColors');
            }

            this.initGoogleAnalytics(response);

            this.initGoogleTagManager(response);

            this.initGoogleMaps(response);

            this.preloadImages(response);

            this.setFavicon(response);

            this.setCustomCss(response);

            this.setLanguages(response);

            setTimeout(() => {
              this.store.commit('config', response);
              this.store.commit('configError', undefined);
              // CHECK CONFIG ?
              resolve(response);
            }, sleep);
          })
          .catch((error: ErrorModel) => {
            this.store.commit('config', undefined);
            this.store.commit('configError', error.errorI18NMessage);
            reject(error);
          });
      });
    }

    // } else { // Not Used
    //  // already loaded.. we do nothing
    //  return new Promise<ConfigModel>(resolve => {
    //    resolve(this.store.state.config!);
    //  });
    //}
  }
  setLanguages(response: ConfigModel) {
    const availableLocales: Language[] = [];
    for (const key in response.translations) {
      const language = response.translations[key] as TranslationModel;
      if (language) {
        availableLocales.push(language);
        let messages = language;
        if (key === 'english') {
          messages = loadEnglishLocale();
          merge(messages, language);
          i18n.global.fallbackLocale = language.code;
        }
        i18n.global.setLocaleMessage(language.code, messages);
      }
    }

    // sort availableLocales by order
    availableLocales.sort((a, b) => {
      return a.order - b.order;
    });

    if (!availableLocales.length) {
      availableLocales.push({
        code: 'en',
        locale: 'en',
        longName: 'English',
        shortName: 'EN',
        numberFormat: 'en',
        dateFormat: 'en',
        dateFormatFull: 'en',
        order: 1,
        direction: 'ltr',
      });
    }

    this.store.commit('availableLocales', availableLocales);
  }
  preloadImages(data: ConfigModel) {
    preload(data.lookAndFeel.bookingBanner);
    preload(data.lookAndFeel.resourceBookingBanner);
    preload(data.lookAndFeel.dashboardBanner);
    preload(data.lookAndFeel.profileBanner);
    preload(data.lookAndFeel.paymentsBanner);
    preload(data.lookAndFeel.clipcardBanner);
    preload(data.lookAndFeel.courseBanner);
    preload(data.lookAndFeel.campsBanner);
    preload(data.lookAndFeel.siteLogo);
    preload(data.lookAndFeel.favicon);
  }

  setFavicon(data: ConfigModel) {
    if (data.lookAndFeel.favicon) {
      const link: HTMLLinkElement = document.querySelector("link[rel~='icon']")!;
      link.href = data.lookAndFeel.favicon;
    }
  }

  setCustomCss(data: ConfigModel) {
    if (data.lookAndFeel.customCSS) {
      const styleSheet = document.createElement('style');
      styleSheet.innerText = data.lookAndFeel.customCSS;
      document.head.appendChild(styleSheet);
    }
  }

  loadCentersForArea(): Promise<CenterModel[]> {
    return backendApi.get('config/load-centers-for-area');
  }

  loadStaffCenters(roles: string[]): Promise<CenterModel[]> {
    return backendApi.get('config/load-staff-centers', { roles });
  }

  initGoogleAnalytics(data: ConfigModel) {
    function gtag() {
      window.dataLayer.push(arguments);
    }

    if (data.general.googleAnalyticsClientId || data.general.googleAnalyticsExerpId) {
      // istanbul ignore next
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      gtag('js', new Date());
      // @ts-ignore
      data.general.googleAnalyticsClientId && gtag('config', data.general.googleAnalyticsClientId);
      // @ts-ignore
      data.general.googleAnalyticsExerpId && gtag('config', data.general.googleAnalyticsExerpId);
    }
  }

  initGoogleTagManager(data: ConfigModel) {
    if (data.general.googleTagManagerClientId) {
      /* istanbul ignore next */
      window.dataLayer = window.dataLayer || [];

      window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      const f = document.getElementsByTagName('script')[0];
      const j = document.createElement('script') as HTMLScriptElement;
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + data.general.googleTagManagerClientId;
      f.parentNode && f.parentNode.insertBefore(j, f);
    }
  }

  initGoogleMaps(data: ConfigModel) {
    if (data.general.googleMapAPIKey) {
      this.app.use(VueGoogleMaps, {
        load: {
          key: data.general.googleMapAPIKey, // 'AIzaSyAmlmN3Spuolpvy8xtSeeYK2KgH0166I90',
          libraries: 'places,geometry',
        },
      });
    } else {
      // we init the component anyway, otherwise Vue complains about missing Component declaration even though it is not used
      this.app.use(VueGoogleMaps, {
        load: {
          libraries: 'places,geometry',
        },
      });
    }
  }
}

function setCssVariables(lookAndFeelConfig: ConfigLookAndFeelModel): boolean {
  let result = true;
  if (lookAndFeelConfig.highlightColor) {
    result = result && setColor(lookAndFeelConfig.highlightColor, 'highlight');
  }
  if (lookAndFeelConfig.makeAwareColor) {
    result = result && setColor(lookAndFeelConfig.makeAwareColor, 'makeAwareColor');
  }
  if (lookAndFeelConfig.bannerSummaryBackgroundColor) {
    result = result && setColor(lookAndFeelConfig.bannerSummaryBackgroundColor, 'bannerSummaryBackgroundColor');
  }
  if (lookAndFeelConfig.bannerSummaryTextColor) {
    result = result && setColor(lookAndFeelConfig.bannerSummaryTextColor, 'bannerSummaryTextColor');
  }
  if (lookAndFeelConfig.bannerSummaryButtonBackgroundColor) {
    result = result && setColor(lookAndFeelConfig.bannerSummaryButtonBackgroundColor, 'bannerSummaryButtonBackgroundColor');
  }
  result = result && setColor(lookAndFeelConfig.mainColor!, 'primary');
  result = result && setColor(lookAndFeelConfig.secondaryColor!, 'secondary');
  return result;
}

function checkLookAndFeel(lookAndFeel: ConfigLookAndFeelModel): boolean {
  if (!lookAndFeel.mainColor) {
    return false;
  }
  if (!lookAndFeel.secondaryColor) {
    return false;
  }
  if (!lookAndFeel.siteLogo) {
    return false;
  }
  return true;
}

function preload(image: string | undefined) {
  if (image) {
    const img = new Image();
    img.src = image;
  }
}
function merge(whole: any, overrides: any): any {
  // merge all properties of source into destination
  for (const property in overrides) {
    if (whole[property] && (overrides[property] || overrides[property].trim() === '')) {
      /*
        trim check is to avoid using the embedded/default translation if configured translation
        set to empty string or string with a space
      */
      if (typeof whole[property] === 'object' && typeof overrides[property] === 'object') {
        merge(whole[property], overrides[property]);
      } else {
        whole[property] = overrides[property];
      }
    }
  }
}
