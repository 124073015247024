import CoursesService from '@/services/courses.service';
import ForgotPasswordService from '@/services/forgot-password.service';
import PaymentService from '@/services/payment.service';
import PersonService from '@/services/person.service';
import 'tailwindcss/tailwind.css';
import VNetworkGraph from 'v-network-graph';
import 'v-network-graph/lib/style.css';
import { createApp } from 'vue';
import Toast, { PluginOptions } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import App from './App.vue';
import i18n from './i18n';
import router from './router';
import BookingService from './services/booking.service';
import CampsService from './services/camps.service';
import ClassesService from './services/classes.service';
import ClipcardService from './services/clipcard.service';
import ConfigurationService from './services/configuration.service';
import DashboardService from './services/dashboard.service';
import InvoicesService from './services/invoices.service';
import PaymentAgreementService from './services/payment-agreement.service';
import QuestionnaireService from './services/questionnaire.service';
import ResourceBookingService from './services/resource-booking.service';
import ShoppingBasketService from './services/shopping-basket.service';
import SubscriptionService from './services/subscription.service';
import BringAFriendService from './services/bring-a-friend.service';
import ReferAFriendService from './services/refer-a-friend.service';
import DocumentService from './services/documents.service';
import '@/directives/Tooltip/_tooltip.css';
import UserService, {
  LOCAL_STORAGE_AUTHENTICATION_SSO_HEADER,
  LOCAL_STORAGE_AUTHENTICATION_SSO_MAPI_TOKEN,
  LOCAL_STORAGE_AUTHENTICATION_SSO_TOKEN,
  LOCAL_STORAGE_AUTHENTICATION_SSO_USER,
  LOCAL_STORAGE_AUTHENTICATION_SSO_COMPANY,
  LOCAL_STORAGE_AUTHENTICATION_TOKEN,
} from './services/user.service';
import { setupAxiosInterceptors } from './shared/axios-interceptor';
import {
  checkIfDateIsValid,
  dayMonthString,
  formatDateStringToLocale,
  formatFullDateTimeStringToLocale,
  formatTimeStringToLocale,
  weekDayString,
} from './shared/date-utils';
import { changeFlowStepTitle, joinFlowStepTitle, userIsActive } from './shared/member-utils';
import {
  addressToString,
  capitalizeFirstLetter,
  checkIfStaffActingForMember,
  formatAmountToLocale,
  getAddonImage,
  getClassImage,
  getMinimumDateForCreationNewPerson,
  getToday,
  handleSignOut,
  staffIsAllowedToPay,
  toTitleCase,
} from './shared/utils';
import store from './store';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { SSOHeaderEnum } from '../../common/src';
import {
  addRedirectRoute,
  addSlashAtBeginning,
  checkRedirects,
  hasAccess,
  navigateToSibling,
  redirectToPaymentLink,
  redirectUrl,
  removeRedirectUrl,
  visibleMenu,
} from './shared/route-utils';
import StaffManagerService from './services/staff-manager.service';
import directives from './directives';
import LTPSService from './services/ltps.service';

const app = createApp(App);

const configurationService = new ConfigurationService(store, app);
const userService = new UserService(store, configurationService);
const bookingService = new BookingService();
const classesService = new ClassesService();
const dashboardService = new DashboardService();
const coursesService = new CoursesService();
const campsService = new CampsService();
const invoicesService = new InvoicesService();
const clipcardService = new ClipcardService();
const paymentService = new PaymentService();
const subscriptionService = new SubscriptionService();
const paymentAgreementService = new PaymentAgreementService();
const personService = new PersonService();
const forgotPasswordService = new ForgotPasswordService();
const questionnaireService = new QuestionnaireService();
const resourceBookingService = new ResourceBookingService();
const shoppingBasketService = new ShoppingBasketService();
const bringAFriendService = new BringAFriendService();
const referAFriendService = new ReferAFriendService();
const documentService = new DocumentService();
const staffManagerService = new StaffManagerService();
const ltpsService = new LTPSService();

router.beforeEach((to, from, next) => {
  if (to.query.lang) {
    localStorage.language = to.query.lang;
    // remove lang from query
    const query = { ...to.query };
    delete query.lang;
    return next({ path: to.path, query });
  }

  let token, mapiToken, externalId, isCompany: string;
  let force = false;
  if (to.path.startsWith('/sso')) {
    token = to.query.token as string;
    externalId = to.query.externalId as string;
    mapiToken = to.query.mapiToken as string;
    isCompany = to.query.isCompany as string;
    // Company - This is just a workarount. Needs to be managed globally later
    if (isCompany === 'true') {
      to.query.next = '/company/billing-info';
    }
    store.commit('user', undefined);
    sessionStorage.removeItem(LOCAL_STORAGE_AUTHENTICATION_TOKEN);
    userService.clearStorage(LOCAL_STORAGE_AUTHENTICATION_SSO_TOKEN);
    userService.clearStorage(LOCAL_STORAGE_AUTHENTICATION_SSO_MAPI_TOKEN);
    userService.clearStorage(LOCAL_STORAGE_AUTHENTICATION_SSO_USER);
    userService.clearStorage(LOCAL_STORAGE_AUTHENTICATION_SSO_COMPANY);
    userService.clearStorage(LOCAL_STORAGE_AUTHENTICATION_SSO_HEADER);
    sessionStorage.setItem(LOCAL_STORAGE_AUTHENTICATION_SSO_TOKEN, String(token));
    if (mapiToken) {
      sessionStorage.setItem(LOCAL_STORAGE_AUTHENTICATION_SSO_MAPI_TOKEN, mapiToken);
    }
    sessionStorage.setItem(LOCAL_STORAGE_AUTHENTICATION_SSO_USER, String(externalId));
    sessionStorage.setItem(LOCAL_STORAGE_AUTHENTICATION_SSO_COMPANY, String(isCompany));
    const ssoHeader = to.query.header === '1' ? SSOHeaderEnum.SMALL : SSOHeaderEnum.TINY;
    store.commit('headerType', ssoHeader);
    sessionStorage.setItem(LOCAL_STORAGE_AUTHENTICATION_SSO_HEADER, ssoHeader);
    force = true;
  }

  // when purchasing a plan, we need to reload the member to make sure that their status hasn't changed in the meantime
  // BASICALLY all pages that call userIsActive() should have the user reloaded.
  let forceAccountReload = false; // verify if it's a staff doing the purchase for a member!! (check needle --refresh-user-vs-staff--). Should be fine if we use the meta "reloadAccount", Check anyway.
  // TODO: for /profile, we just need to force the reload for the "My Subscriptions" page, we probably need to modify the route to be /profile/xxxx instead of /profile?selectedTab=xxxx
  if (
    to.meta.reloadAccount ||
    (to.path === '/profile' && (to.query.selectedTab === 'my-subscriptions' || to.query.selectedTab === 'my-billing-info'))
  ) {
    forceAccountReload = true;
  }

  configurationService
    .loadConfiguration(force)
    .then(() => {
      if (checkRedirects(to.path, next)) {
        return;
      }
      if (to.matched.length === 0 || !visibleMenu(to, store.state.config)) {
        return next('/404');
      }
      if (to.path === '/login') {
        return next();
      }
      if (to.path === '/token-error') {
        return next();
      }
      removeRedirectUrl(to);
      userService
        .account(force, forceAccountReload)
        .then(account => {
          if (account.ssoHeaderType) {
            store.commit('headerType', account.ssoHeaderType);
          }
          // the user is connected:
          if (hasAccess(to, account)) {
            return next();
          } else {
            // Default page if they can't go to the selected page
            return next('/unauthorized');
          }
        })
        .catch(() => {
          // the user is not connected, but we don't need access to this page
          if (hasAccess(to, undefined)) {
            return next();
          } else {
            // The user needs access: go to login or to an error page
            addRedirectRoute(to.fullPath);
            return next('/login');
          }
        });
    })

    .catch(e => {
      console.log('ERROR LOADING CONFIG');
      //Added this to ensure if session times out and config is not loaded, need to refresh all settings to retry.
      if (e.httpCode === 440) {
        store.commit('config', undefined);
        userService.signOut();
      }
    });
});

setupAxiosInterceptors(status => {
  if (status === 403 || status === 401) {
    console.log('Unauthenticated');
  } else if (status === 440) {
    handleSignOut(userService, configurationService, { sessionTimeoutError: 'general.sessionTimeoutError' });
  }
});

app
  .use(i18n)
  .use(store)
  .use(router)
  .use(VNetworkGraph)
  // .component('Datepicker', Datepicker)
  .provide('configurationService', configurationService) //
  .provide('bookingService', bookingService) //
  .provide('classesService', classesService) //
  .provide('dashboardService', dashboardService) //
  .provide('coursesService', coursesService) //
  .provide('campsService', campsService) //
  .provide('userService', userService) //
  .provide('invoicesService', invoicesService) //
  .provide('clipcardService', clipcardService) //
  .provide('subscriptionService', subscriptionService) //
  .provide('paymentAgreementService', paymentAgreementService) //
  .provide('personService', personService) //
  .provide('paymentService', paymentService) //
  .provide('forgotPasswordService', forgotPasswordService)
  .provide('questionnaireService', questionnaireService)
  .provide('shoppingBasketService', shoppingBasketService)
  .provide('bringAFriendService', bringAFriendService)
  .provide('referAFriendService', referAFriendService)
  .provide('documentService', documentService)
  .provide('resourceBookingService', resourceBookingService)
  .provide('staffManagerService', staffManagerService)
  .provide('ltpsService', ltpsService);

directives(app);

const toastOptions: PluginOptions = {
  timeout: 2000,
};
app.use(Toast, toastOptions);

app.config.globalProperties.navigateToSibling = navigateToSibling;
app.config.globalProperties.formatDateStringToLocale = formatDateStringToLocale;
app.config.globalProperties.formatTimeStringToLocale = formatTimeStringToLocale;
app.config.globalProperties.formatFullDateTimeStringToLocale = formatFullDateTimeStringToLocale;
app.config.globalProperties.formatAmountToLocale = formatAmountToLocale;
app.config.globalProperties.dayMonthString = dayMonthString;
app.config.globalProperties.weekDayString = weekDayString;
app.config.globalProperties.getClassImage = getClassImage;
app.config.globalProperties.getAddonImage = getAddonImage;
app.config.globalProperties.capitalizeFirstLetter = capitalizeFirstLetter;
app.config.globalProperties.toTitleCase = toTitleCase;
app.config.globalProperties.userIsActive = userIsActive;
app.config.globalProperties.checkIfDateIsValid = checkIfDateIsValid;
app.config.globalProperties.getMinimumDateForCreationNewPerson = getMinimumDateForCreationNewPerson;
app.config.globalProperties.getToday = getToday;
app.config.globalProperties.checkIfStaffActingForMember = checkIfStaffActingForMember;
app.config.globalProperties.staffIsAllowedToPay = staffIsAllowedToPay;
app.config.globalProperties.redirectToPaymentLink = redirectToPaymentLink;
app.config.globalProperties.joinFlowStepTitle = joinFlowStepTitle;
app.config.globalProperties.changeFlowStepTitle = changeFlowStepTitle;
app.config.globalProperties.addressToString = addressToString;

app.mount('#app');
